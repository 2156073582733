'use strict';
class LangMenu {
    constructor(localization) {
        const that = this;
        this.localization = localization;
        this.initLangMenu();
        this.$languageMenuBlock = jQuery('#languageMenuBlock');
        jQuery(document).mouseup((e)=>{
            if (!that.$languageMenuBlock.is(e.target) && that.$languageMenuBlock.has(e.target).length === 0){
                that.$languageMenuBlock.fadeTo('fast', 0);
                that.$languageMenuBlock.css('visibility', 'hidden');
            }
        });
    }

    initLangMenu() {
        const that = this;
        const currLang = that.localization.getCurrLng();
        $('#currLangLabel').html(currLang);
        const $langMenuOpener = jQuery('#langMenuOpener');
        const $langMenuSelection = jQuery('.langMenuSelection');
        $langMenuOpener.click(()=>{
            that.$languageMenuBlock.css('visibility', 'visible');
            that.$languageMenuBlock.fadeTo('fast', 1);
        });
        $langMenuSelection.on('click',(el)=>{
           const targetLang = $(el.delegateTarget).data('targetLang');
            that.localization.changeLng(targetLang).then(() => {
                jQuery(document).trigger('langMenu::langChanged');
                $('#currLangLabel').html(targetLang);
                that.$languageMenuBlock.fadeTo('fast', 0);
                that.$languageMenuBlock.css('visibility', 'hidden');
            });
        });
    }

    getTargetLng(){
        const currLng = this.localization.getCurrLng();
        const targetLng = (currLng === 'en') ? 'de' : 'en';
        return targetLng;
    }
}
export default LangMenu;
