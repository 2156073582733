'use strict';

import FormUtils from '../utils/formUtils';
class TicketOrderForm {
    constructor() {
        const that = this;
        this.formName = 'ticketOrderForm';
        this.formUtils = new FormUtils(this.formName);
        this.formUtils.initJcf();
        
        $('#'+that.formName+'Retry').click((e) => {
            that.backToForm(false);
        });

        $('#'+that.formName+'Back').click((e) => {
            that.backToForm(false);
        });

        $('#'+that.formName+'Submit').click((e) => {
            e.preventDefault();
            that.handleSubmit();
        });

        $(document).on('langMenu::langChanged',()=>{
            that.formUtils.initJcf();
        });
    }


    backToForm(reset) {
        if(reset) {
            $('form#' +this.formName + ' input[type=text]').each((index, inputEl)=>{
                $(inputEl).val('');
            });
        }
        $('#'+this.formName).removeClass('hidden');
        $('#'+this.formName+'Success').addClass('hidden');
        $('#'+this.formName+'Error').addClass('hidden');
        $('#'+this.formName+'BackHolder').addClass('hidden');
        $('#'+this.formName+'RetryHolder').addClass('hidden');
        this.formUtils.setLoadingState(false);
    }

    validCodeAmount() {
        const maxAmount = 20;
        const inputName = 'codeAmount';
        const codeAmountInput = parseInt($('#'+inputName).val());
        const isValid = ((codeAmountInput > 0) && (codeAmountInput <= maxAmount));
        if(!isValid) {
            $('#'+inputName+'Error').removeClass('hidden');
        }
        return isValid;
    }

    handleSubmit(){
        const validDefault = this.formUtils.validate();
        const validCodeAmount = this.validCodeAmount();
        if(validDefault && validCodeAmount) {
            $('#'+this.formName+'Success').addClass('hidden');
            $('#'+this.formName+'Error').addClass('hidden');
            $('#'+this.formName+'LimitError').addClass('hidden');
            $('#hoursRemaining').text('0');
            $('#hoursCreated').text('0');
            $('#'+this.formName+'RetryHolder').addClass('hidden');
            $('#'+this.formName+'BackHolder').addClass('hidden');
            $('#'+this.formName+'Submit').addClass('jcf-disabled');
            this.processTicketOrder().then((resData)=>{
                $('#'+this.formName).addClass('hidden');
                if(resData && resData.isLimit) {
                    $('#hoursRemaining').text(resData.remaining);
                    $('#hoursCreated').text(resData.orderDiff);
                    $('#'+this.formName+'LimitError').removeClass('hidden');
                    $('#'+this.formName+'BackHolder').removeClass('hidden');
                } else {
                    $('#'+this.formName+'Success').removeClass('hidden');
                }
            }).catch((error)=>{
                $('#'+this.formName).addClass('hidden');
                $('#'+this.formName+'RetryHolder').removeClass('hidden');
                $('#'+this.formName+'Error').removeClass('hidden');
            });
        }
    }
    
    processTicketOrder() {
        return new Promise((resolve, reject) => {
            const that = this;
            const data = $('form#' +this.formName + ' input').serializeArray();
            data.push({'name':'cLang','value': $('#cLang').val()});
            data.push({'name':'tRegion','value': $('#tRegion').val()});
            const targetUrl = '/api/api.php';
            that.formUtils.setLoadingState(true);
            data.push({'name': 'cmd', 'value': 'order'});
            $.ajax({
                type: 'POST',
                data: {data},
                dataType: 'json',
                url: targetUrl,
                success: function(res){
                    if(res.done) {
                        resolve(res.resData);
                    } else {
                        reject(res.errorMessage || res.responseText || res.infoMessage);
                    }
                    that.formUtils.setLoadingState(false);
                },
                error: function (error) {
                    that.formUtils.setLoadingState(false);
                    reject(error.message || error.responseText);
                }
            });
        });
    }
}
export default TicketOrderForm;
