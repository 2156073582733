import TicketOrderForm from "./components/ticketOrderForm";
import Admin from "./components/admin";
import Localization from "./components/localization";
import LangMenu from './components/langMenu';
import "./vendors/retinaCover";

class App{
    init() {
        const that = this;
        const localization = new Localization();
        this.filename = this.getCurrFilename();
        localization.init(null,this.filename).then(()=>{
            new LangMenu(localization);
            that.initView(localization);
        }).catch((err) => {
            console.error('language initialization failed: ', err);
            that.initView();
        });
        jQuery('.bg-stretch').retinaCover();
    }

    initView(localization) {
        switch(this.filename) {
            case 'admin':
                new Admin(localization);
                break;
            default:
                new TicketOrderForm();
        }
    }

    getCurrFilename() {
        const url = window.location.pathname;
        let fullname = url.substring(url.lastIndexOf('/')+1);
        const filename = fullname ? fullname.replace(/\.[^/.]+$/, "") : 'index';
        return filename;
    }
}

$( document ).ready(() => {
    new App().init();
});
