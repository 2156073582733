/*
 * jQuery retina cover plugin
 */
;

(function ($) {
    'use strict';

    var styleRules = {};
    var templates = {
        '2x': ['(-webkit-min-device-pixel-ratio: 1.5)', '(min-resolution: 192dpi)', '(min-device-pixel-ratio: 1.5)', '(min-resolution: 1.5dppx)'],
        '3x': ['(-webkit-min-device-pixel-ratio: 3)', '(min-resolution: 384dpi)', '(min-device-pixel-ratio: 3)', '(min-resolution: 3dppx)']
    };

    function addSimple(imageSrc, media, id) {
        var style = buildRule(id, imageSrc);
        addRule(media, style);
    }

    function addRetina(imageData, media, id) {
        var currentRules = templates[imageData[1]].slice();
        var patchedRules = currentRules;
        var style = buildRule(id, imageData[0]);

        if (media !== 'default') {
            patchedRules = $.map(currentRules, function (ele, i) {
                return ele + ' and ' + media;
            });
        }

        media = patchedRules.join(',');
        addRule(media, style);
    }

    function buildRule(id, src) {
        return '#' + id + '{background-image: url("' + src + '");}';
    }

    function addRule(media, rule) {
        var $styleTag = styleRules[media];
        var styleTagData;
        var rules = '';

        if (media === 'default') {
            rules = rule + ' ';
        } else {
            rules = '@media ' + media + '{' + rule + '}';
        }

        if (!$styleTag) {
            styleRules[media] = $('<style>').text(rules).appendTo('head');
        } else {
            styleTagData = $styleTag.text();
            styleTagData = styleTagData.substring(0, styleTagData.length - 2) + ' }' + rule + '}';
            $styleTag.text(styleTagData);
        }
    }

    $.fn.retinaCover = function () {
        return this.each(function () {
            var $block = $(this);
            var $items = $block.children('[data-srcset]');
            var id = 'bg-stretch' + Date.now() + (Math.random() * 1000).toFixed(0);

            if ($items.length) {
                $block.attr('id', id);
                $items.each(function () {
                    var $item = $(this);
                    var data = $item.data('srcset').split(', ');
                    var media = $item.data('media') || 'default';
                    var dataLength = data.length;
                    var itemData;
                    var i;

                    for (i = 0; i < dataLength; i++) {
                        itemData = data[i].split(' ');

                        if (itemData.length === 1) {
                            addSimple(itemData[0], media, id);
                        } else {
                            addRetina(itemData, media, id);
                        }
                    }
                });
            }

            $items.detach();
        });
    };
})(jQuery);