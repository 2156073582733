'use strict';
import jcf from "jcf";
class FormUtils {
    constructor(formName) {
        this.formName = formName;
    }

    validate() {
        let errorsCount = 0;
        $('form#' +this.formName + ' .required').each((index, inputEl)=>{
            const inputName = $(inputEl).attr('name');
            const inputVal = $(inputEl).val();
            if(!(inputVal && (inputVal.trim()).length>0)) {
                errorsCount++;
                $('#'+inputName+'Error').removeClass('hidden');
            } else {
                $('#'+inputName+'Error').addClass('hidden');
            }
        });
        return (errorsCount===0);
    }

    initJcf() {
        jcf.setOptions('Select', {
            wrapNative: false,
            wrapNativeOnMobile: false,
            fakeDropInBody: false
        });
        jcf.replaceAll('fieldset');
    }

    setLoadingState(loading) {
        if(loading) {
            $('form#' +this.formName + ' input').each((index, inputEl)=>{
                $(inputEl).addClass('jcf-disabled');
            });
            $('form#' +this.formName + ' .jcf-select').each((index, inputEl)=>{
                $(inputEl).addClass('jcf-disabled');
            });
            $('#'+this.formName+'Loader').removeClass('hidden');
        } else {
            $('#'+this.formName+'Loader').addClass('hidden');
            $('form#' +this.formName + ' input').each((index, inputEl)=>{
                $(inputEl).removeClass('jcf-disabled');
            });

            $('form#' +this.formName + ' .jcf-select').each((index, inputEl)=>{
                $(inputEl).removeClass('jcf-disabled');
            });

        }
    }
}
export default FormUtils;
