'use strict';
import dt from "datatables.net";
import FormUtils from '../utils/formUtils';
class Admin {
    constructor(localization) {
        this.formName = 'loginForm';
        this.localization = localization;
        const that = this;
        that.login = false;
        that.dtOrders = null;
        that.dtCodesAssigned = null;
        that.dtCodesFree = null;
        that.loginProcessStartHint = 'Processing, please wait ...';
        that.loginProcessFailedHint = 'Login failed';
        that.initDtDone = false;
        that.initDtDoneCodesAssigned = false;
        that.initDtDoneCodesFree = false;
        that.formUtils = new FormUtils(this.formName);
        $('#loginProcessHintMessage').text(that.loginProcessStartHint);

        $('#viewSelector').on('change',()=>{
           that.viewChange();
        });

        this.processLogin(null, true).then(()=>{
            that.loginSuccess();
        }).catch((e)=>{
            $('#loginProcessHint').css('display', 'none');
            $('#loginFormContainer').css('display', 'block');
            $('#authContainer').addClass('unauthorized');
        });

        $('#logoutBtn').click((e) => {
            e.preventDefault();
            that.processLogin(null, null, true).then(()=>{
                that.logoutDone();
            }).catch((msg)=>{
                that.logoutDone();
                console.warn('logout failed: ', msg);
            })
        });

        $('#'+that.formName+'Submit').click((e) => {
            e.preventDefault();
            const that = this;
            const data = $('form#' +that.formName + ' input').serializeArray();
            this.processLogin(data, false).then(()=>{
               that.loginSuccess();
            }).catch(()=>{
                $('#loginFormContainer').css('display', 'block');
                $('#loginProcessHintMessage').text(that.loginProcessFailedHint);
                $('#authContainer').addClass('unauthorized');
            });
        });

        $(document).on('langMenu::langChanged',()=>{
            that.initDtDone = false;
            that.dtOrders.destroy();
            that.initDtOrders();

            that.initDtDoneCodesAssigned = false;
            if(that.dtCodesAssigned) {
                that.dtCodesAssigned.destroy();
            }
            that.initDtCodesAssigned();

            that.initDtDoneCodesFree = false;
            if(that.dtCodesFree) {
                that.dtCodesFree.destroy();
            }
            that.initDtCodesFree();

            that.loadFreeTicketsCount();
        });

        $('#btnImportProcess').click(()=>{
           that.handleFileImport();
        });
    }

    handleFileImport() {
        const importFileName = $('#import_file_input').val();
        $('#importResult').html('processing import ...');
        if(!importFileName) {
            $('#importResult').html('missing import file name');
            return false;
        }
        $('#importResult').html('processing import file ' + importFileName+ '.csv ...');
        const data = [];
        const targetUrl = '/api/api.php';
        $('#freeTicketsCountHolder').css('visibility', 'hidden');
        data.push({'name': 'cmd', 'value': 'import'});
        data.push({'name': 'importFileName', 'value': importFileName});
        $.ajax({
            type: 'POST',
            data: {data},
            dataType: 'json',
            url: targetUrl,
            success: function(res){
                $('#importResult').html(res.infoMessage);
            },
            error: function (error) {
                console.error('could not retrieve free tickets count: ', error);
            }
        });
    }

    logoutDone(){
        $('#logoutBtnContainer').css('display', 'none');
        $('#loginProcessHint').css('display', 'none');
        $('#loginFormContainer').css('display', 'block');
        $('#authContainer').addClass('unauthorized');
        this.login = false;
    }

    loginSuccess(){
        this.loadFreeTicketsCount();
        if(!this.initDtDone) {
            this.initDtOrders();
        }
        if(!this.initDtDoneCodesFree) {
            this.initDtCodesFree();
        }
        if(!this.initDtDoneCodesAssigned) {
            this.initDtCodesAssigned();
        }

        const initialView = 'codeTableHolderAssigned' ;
        $('viewSelector').val(initialView);
        this.viewChange();
        $('#logoutBtnContainer').css('display', 'block');
        $('#loginProcessHint').css('display', 'none');
        $('#loginFormContainer').css('display', 'none');
        $('#authContainer').removeClass('unauthorized');
        this.login = true;
    }

    loadFreeTicketsCount() {
        let count = 0;
        const data = [];
        const targetUrl = '/api/api.php';
        $('#freeTicketsCountHolder').css('visibility', 'hidden');
        data.push({'name': 'cmd', 'value': 'freeTicketsCount'});
        $.ajax({
            type: 'POST',
            data: {data},
            dataType: 'json',
            url: targetUrl,
            success: function(res){
                if(res.done) {
                    count = res.resData.freeTicketsCount;
                }
                $('#ticketsCount').html(count);
                $('#freeTicketsCountHolder').css('visibility', 'visible');
            },
            error: function (error) {
                console.error('could not retrieve free tickets count: ', error.responseText);
            }
        });
    }

    viewChange() {
        $('.tabHolder').css('display', 'none');
        const newView = $('#viewSelector').val();
        console.log('set view visible: ', newView);
        $('#'+newView).css('display', 'block');
    }

    initDtCodesFree(){
        const that = this;
        const currLang = that.localization ? that.localization.getCurrLng() : 'en';
        that.initDtDoneCodesFree = true;
        that.dtCodesFree = $('#codesFree').DataTable( {
            "language": {
                "url": "/locales/"+currLang+"/dt.json"
            },
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": "/api/dtCodesFree.php",
                "type": "POST"
            }
        } );
    }

    initDtCodesAssigned(){
        const that = this;
        const currLang = that.localization ? that.localization.getCurrLng() : 'en';
        that.initDtDoneCodesAssigned = true;
        that.dtCodesAssigned = $('#codesAssigned').DataTable( {
            "language": {
                "url": "/locales/"+currLang+"/dt.json"
            },
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": "/api/dtCodesAssigned.php",
                "type": "POST"
            }
        } );
    }

    initDtOrders(){
        const that = this;
        const retryBtnHtml = $('#retryBtnHolder').html();
        const currLang = that.localization ? that.localization.getCurrLng() : 'en';
        that.initDtDone = true;
        that.dtOrders = $('#orders').DataTable( {
            "language": {
                "url": "/locales/"+currLang+"/dt.json"
            },
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": "/api/dtOrders.php",
                "type": "POST"
            },
            "columnDefs": [ {
                "targets": -1,
                "data": null,
                "defaultContent": retryBtnHtml
            } ]
        } );

        $('#orders tbody').on( 'click', 'button', function () {
            const $rowId = $(this).parents('tr');
            const data = that.dtOrders.row( $rowId ).data();
            const uid = data[ 0 ];
            const processingMessage = $('#resendProcessingMsg').html();
            const processingResultMsg = $('#resendProcessingResultMsg').html();
            $('#retryResult').html(processingMessage);
            that.processRetry(uid).then((newData)=>{
                that.dtOrders.row( $rowId ).data( data ).draw();
                $('#retryResult').html(processingResultMsg);
            }).catch((e)=>{
                $('#retryResult').html(e);
            });
        });

    }

    processLogin(loginFormData, isSession, logout) {
        $('#loginProcessHintMessage').text(this.loginProcessStartHint);
        $('#loginFormContainer').css('display', 'none');
        $('#logoutBtnContainer').css('display', 'none');
        $('#loginProcessHint').css('display', 'block');
        return new Promise((resolve,reject)=>{
            const targetUrl = '/api/' + (logout ? 'logout': 'login') + '.php';
            $.ajax({
                type: 'POST',
                data: {loginFormData},
                dataType: 'json',
                url: targetUrl,
                success: function(res){
                    console.log('isSession:', isSession, ' res:', res);
                    if(isSession && res.sessionLogin) {
                        resolve();
                    } else {
                        if(res.done) {
                            resolve();
                        } else {
                            reject(res.errorMessage || res.responseText);
                        }
                    }
                },
                error: function (error) {
                    reject(error.message || error.responseText);
                }
            });
        });
    }

    processRetry(uid) {
        return new Promise((resolve, reject) => {
            const data = [];
            const targetUrl = '/api/api.php';
            data.push({'name': 'cmd', 'value': 'retry'});
            data.push({'name': 'uid', 'value': uid});
            $.ajax({
                type: 'POST',
                data: {data},
                dataType: 'json',
                url: targetUrl,
                success: function(res){
                    if(res.done) {
                        resolve(res.resData);
                    } else {
                        reject(res.errorMessage || res.responseText || res.infoMessage);
                    }
                },
                error: function (error) {
                    reject(error.message || error.responseText);
                }
            });
        });
    }

}
export default Admin;
