'use strict';
import i18n from 'i18next'
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const I18N_LANGUAGES = ['en', 'de'];
export const I18N_FALLBACK_LNG = 'de';
export const I18N_FALLBACK_NS = 'index';
export const HIDE_URL_INDEX = true;
export const I18N_USE_LNG = true;
export const I18N_DEBUG = false;
export const I18N_CONF = {
    tName: 't', // --> appends $.t = i18next.t
    i18nName: 'i18n', // --> appends $.i18n = i18next
    handleName: 'localize', // --> appends $(selector).localize(opts);
    selectorAttr: 'data-i18n', // selector for translating elements
    targetAttr: 'i18n-target', // data-() attribute to grab target element to translate (if different than itself)
    optionsAttr: 'i18n-options', // data-() attribute that contains options, will load/set if useOptionsAttr = true
    useOptionsAttr: false, // see optionsAttr
    parseDefaultValueFromContent: true // parses default values from content ele.val or ele.text
};
class Localization {
    constructor() {
        jqueryI18next.init(i18n, $, I18N_CONF);
        this.currLng = null;
    }
    // NOTE: use on lang changed as localization.load(currPage, newLang).then(()=>{}).catch((err)=>{})
    init(_lng, _ns){
        const that = this;
        return new Promise((resolve, reject) => {
            (async ()=>{
                // TODO: set USE_LNG flag for url handling, use I18N_FALLBACK_NS for no multilang
                // TODO: get supportedLngs from global config, debug from env
                try {
                    let doUrlUpdate = false;
                    const opts = {
                        debug: I18N_DEBUG,
                        supportedLngs: I18N_LANGUAGES,
                        fallbackLng: I18N_FALLBACK_LNG,
                        backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
                    }
                    if(!_lng) {
                        const lngByUrl = that.getUrlLng();
                        if(lngByUrl) {
                            opts.lng = lngByUrl;
                        } else {
                            opts.lng = I18N_FALLBACK_LNG;
                            doUrlUpdate = I18N_USE_LNG;
                        }
                    } else {
                        opts.lng = _lng;
                        doUrlUpdate = I18N_USE_LNG;
                    }
                    if(!_ns) {
                        const urlNs = that.getCurrPageNs();
                        opts.ns = urlNs ? urlNs : I18N_FALLBACK_NS;
                    } else {
                        opts.ns = I18N_FALLBACK_NS;
                    }
                    opts.defaultNS = opts.ns;
                    await i18n.use(HttpApi).use(LanguageDetector).init(opts, (err, t) => {
                        if(err) {
                            reject(err);
                            return false;
                        }
                        that.currLng = opts.lng;
                        if(doUrlUpdate) {
                            that.updateUrlForLng(opts.lng);
                        }
                        $(document).localize();
                        i18n.on("languageChanged", lng => {
                            that.currLng = lng;
                            console.log('localize by langChange: ', lng);
                            $(document).localize();
                        });
                        resolve();
                    });
                } catch(error) {
                    reject(error);
                }
            })();
        });
    }

    updateUrlForLng(lng) {
        let newLng;
        if(!lng) {
            newLng = i18n.language || window.localStorage.i18nextLng;
        } else {
            newLng = lng;
        }
        const urlNs = this.getCurrPageNs();
        let rewriteNs = '';
        if(!(urlNs === 'index' && HIDE_URL_INDEX)) {
            rewriteNs = urlNs ? ( urlNs + '.html' ) : '';
        }
        console.log('update url to lng: ', newLng, ' rewriteNs:', rewriteNs);
        // NOTE: we dont want history here and use replaceState instead of pushState
        history.replaceState({}, null, '/'+newLng+'/' + rewriteNs);
    }

    getCurrPageNs(){
        const url = window.location.pathname;
        let pageFile = url.substring(url.lastIndexOf('/') + 1);
        let validEnding = true;
        const urlPartCount = (url.match(/\//g) || []).length;
        if(urlPartCount<2) {
            validEnding = pageFile.endsWith('.html');
        }
        const currPage = (pageFile && validEnding) ? pageFile.replace(/\.[^/.]+$/, "") : I18N_FALLBACK_NS;
        return currPage;
    }

    getUrlLng() {
        const paths = window.location.pathname.split('/');
        const hasLangParam = (paths.length > 2);
        const lng = hasLangParam ? paths[1] : null;
        return lng;
    }

    getCurrLng() {
        return this.currLng;
    }

    changeLng(lng) {
        const that = this;
        return  new Promise((resolve, reject) =>{
            i18n.changeLanguage(lng).then(() => {
                that.updateUrlForLng(lng);
                resolve();
            }).catch((error)=>{
                reject(error);
            });
        });
    }
}
export default Localization;
